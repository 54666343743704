import React, {useEffect, useMemo, useState} from "react";

import dayjs from "dayjs";
import advancedFormat from 'dayjs/plugin/advancedFormat';
import 'dayjs/locale/ko';
import 'dayjs/locale/en';

import Card from '@mui/material/Card';
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import {Operation} from "../../../models/Operation";
import {Product} from "../../../models/Product";
import {ReservationBase} from "../../../models/Reservation";
import {User} from "../../../models/User";
import {Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import {updateRealtime} from "../../../hooks/firebase";
import {defined} from "chart.js/helpers";
import CardHeader from "@mui/material/CardHeader";
import copy from "../../../utils/copy";
import Stack from "@mui/material/Stack";

dayjs.extend(advancedFormat);

const IGNORE_DISPATCH_PRODUCTS = ['광장시장', '이태원클래스', '서울더버스커스', 'MBC 스튜디오', 'MBC 스튜디오(드라마 리허설)'];
const IGNORE_ANNOUNCE_PRODUCTS = ['MBC 스튜디오', 'MBC 스튜디오(드라마 리허설)'];
const PRIVATE_IDS = ['private', 'vip'];
const DRIVING_CARS = ['스타리아', '쏠라티', 'Hiace']

const SEOUL_ANNOUNCEMENT = `

——————————————
오피스 연락처 01096508388
★ 투어코스/탑승인원/탑승지/탑승시간 전달
★ 투어 전날 저녁 21시까지 채팅 웹 입장 안 하신 손님들께 WhatsApp/Line/Viber 등으로 연락
★ Email 없는 에이전시(T, VI, TE, CV) 같은 경우 손님 연락처 추가하여 직접 연락
★ 동역사 손님 동대문 아닌 ⭐동대문역사문화공원역⭐ 강조, 하루 전 연락 불가 손님 투어 시작 후 연락처 확보 후 채팅 웹 입장 유도
★ 롯데면세점 쿠폰 배부 필수 [회사 단체 번호: 23179785]
——————————————
● 차량 내 음식물 반입 금지 요청
● 기사님 픽업지/시간/투어명/투어 방문 순서 ⭐반드시⭐ 안내
● 기사님 존칭 사용/매너 유지
● 투어 종료 시 손님 개인 소지품 분실 주의 안내 
——————————————
☑ 출근 사진 업로딩 필수, 누락 시 지각비 차감
☑ 투어 시작과 투어 종료 단톡방에 ⭐반드시⭐ 보고
☑ 투어 당일 기상 시 "투어준비중" 단톡방에 보고 
——————————————
※ 예장 주차장 주의사항
※ 버스는 반드시 주차라인에 정차
※ 본인 깃발 번호 사용 필수
※ 버스로 이동 시 반드시 바닥에 표시된 ⭐하얀색 안전선⭐ 따라서 이동, 주차장 가로지르기 금지, 급할 시 버스기사 출입문 이용

`
const TOKYO_ANNOUNCEMENT = `

——————————————
Office Contact: +82 10-9650-8388
★ Provide the tour course, number of passengers, pick-up location, and pick-up time.
★ Contact guests who haven't entered the chat web by 9 PM the day before the tour via WhatsApp, Line, Viber, etc.
★ For agencies without email (T, VI, TE, CV), add the guest's contact information and contact them directly.
——————————————
● Be sure to inform the driver of the pick-up location/time/tour name/tour sequence.
● Use honorifics and maintain manners with the driver.
● At the end of the tour, remind guests to take care of their personal belongings.
——————————————
☑ Upload attendance photo; if missed, late fee will be deducted.
☑ Report in the group chat without fail at the start and end of the tour.
☑ Report in the "Tour Preparation" group chat upon waking up on the day of the tour.

`


function checkVehicleType(vehicle: string): [string, string, string] {
  if (vehicle === '도보') return ['도보', '도보', ''];
  if (vehicle === '스타리아') return ['스타리아', '스타리아', ''];
  if (vehicle === '쏠라티') return ['쏠라티', '쏠라티', ''];
  if (vehicle === '카운티') return ['카운티', '카운티', '기사님'];
  if (vehicle === '45인승') return ['45인승', '버스', '기사님'];
  if (vehicle === 'Walking') return ['Walking', 'Walking', ''];
  if (vehicle === 'Hiace') return ['Hiace', 'Hiace', ''];
  if (vehicle === '18-seater Bus') return ['18-seater Bus', '18-seater Bus', 'Driver'];
  if (vehicle === '32-seater Bus') return ['32-seater Bus', '32-seater Bus', 'Driver'];
  if (vehicle === '45-seater Bus') return ['45-seater Bus', '45-seater Bus', 'Driver'];
  return ['', '', ''];
}


function determineVehicleTypeSeoul(people: number): string {
  if (people <= 7) return '스타리아'
  if (people <= 15) return ('카운티');
  return ('45인승');
}

function determineVehicleTypeBusan(people: number): string {
  if (people <= 7) return ('스타리아');
  if (people <= 15) return ('카운티');
  return ('45인승');
}

function determineVehicleTypeTokyo(people: number): string {
  if (people <= 9) return ('Hiace');
  if (people <= 16) return ('18-seater Bus');
  if (people <= 30) return ('32-seater Bus');
  return ('45-seater Bus');
}

function vehicleAssigner(vehicleDeterminer: (people: number) => string) {
  return function (peopleOrModel: number | string): { model: string, type: string, } {
    const vehicleModel = typeof peopleOrModel === 'string' ? peopleOrModel : vehicleDeterminer(peopleOrModel);
    const [model, type] = checkVehicleType(vehicleModel);
    return {model, type};
  }
}

type Area = {
  id: string,
  name: string,
  vehicles: string[],
  assignVehicle: (people: number) => string,
  pickupNameMap: { [pickupName: string]: string }
  announcement: string,
  dateFormat: string,
  locale: 'ko' | 'en'
}
const AREAS: { [id: string]: Area } = {
  'seoul': {
    id: 'seoul',
    name: 'Seoul',
    vehicles: ['스타리아', '카운티', '45인승', '쏠라티', '도보'],
    assignVehicle: determineVehicleTypeSeoul,
    pickupNameMap: {
      'Hongdae': '홍',
      'Myungdong': '명',
      'Dongdaemoon': '동',
      'Gwanghwamun': '광화문',
      'Jongno': '종로',
      'Yeouido': '여의도',
    },
    locale: 'ko',
    dateFormat: 'M월 D일 dddd',
    announcement: SEOUL_ANNOUNCEMENT
  },
  'busan': {
    id: 'busan',
    name: 'Busan',
    vehicles: ['스타리아', '카운티', '45인승', '쏠라티', '도보'],
    assignVehicle: determineVehicleTypeBusan,
    pickupNameMap: {
      'Busan Station': '부산역',
      'Seomyun': '서면',
      'Haeundaae': '해운대',
    },
    locale: 'ko',
    dateFormat: 'M월 D일 dddd',
    announcement: SEOUL_ANNOUNCEMENT
  },
  'tokyo': {
    id: 'tokyo',
    name: 'Tokyo',
    vehicles: ['Hiace', '18-seater Bus', '32-seater Bus', '45-seater Bus', 'Shared'],
    assignVehicle: determineVehicleTypeTokyo,
    pickupNameMap: {
      'Tokyo Station': 'Tokyo',
      'Shinjuku-nishiguchi': 'Shinjuku',
    },
    locale: 'en',
    dateFormat: 'dddd, MMMM Do, YYYY',
    announcement: TOKYO_ANNOUNCEMENT
  },
}

type TourTeam = {
  date: string,
  productId: string,
  teamId: string,
  teamIdx: number,
  teamCount: number,
  tourId: string,
  guideIds: string[],
  reservations: ReservationBase[],
  pickup?: Pickup,

  vehicleModel?: string,
  vehicleNumber?: string,
  vehicleRent?: boolean,
  driverName?: string,
  driverContact?: string,
  people?: number,
  mergeTeam?: string,
}

function operationToTeams(operation: Operation, date: string): TourTeam[] {
  const tours = operation.tours ?? {};
  return Object.entries(tours).map(([_tourId, tour]) => {
    const _date = date;
    const _productId = tour.productId;
    return Object.entries(tour.teams ?? {})
      .sort(([_, aTeam], [__, bTeam]) => aTeam.idx < bTeam.idx ? -1 : 1)
      .map(([_teamId, team], idx, teams) => {
        const _guideIds = (team.guides ?? []).map(({id}) => id);
        const _reservations = Object.values(team.reservations ?? {});
        const _mergeTeam = team.dispatch?.mergeTeam;
        const _vehicleModel = team.dispatch?.vehicleModel;
        const _vehicleNumber = team.dispatch?.vehicleNumber;
        const _vehicleRent = team.dispatch?.vehicleRent;
        const _driverName = team.dispatch?.driverName;
        const _driverContact = team.dispatch?.driverContact;
        const _pickup = team.dispatch?.pickup;
        const _people = team.dispatch?.people;
        const _teamCount = teams.length;
        return {
          date: _date,
          productId: _productId,
          tourId: _tourId,
          teamId: _teamId,
          teamIdx: idx,
          teamCount: _teamCount,
          guideIds: _guideIds,
          pickup: _pickup,
          reservations: _reservations,
          vehicleModel: _vehicleModel,
          vehicleNumber: _vehicleNumber,
          vehicleRent: _vehicleRent,
          driverName: _driverName,
          driverContact: _driverContact,
          people: _people,
          mergeTeam: _mergeTeam,
        }
      });
  }).flat(1);
}

type Pickup = {
  place: string,
  time: string
}

type TourTeamRow = {
  date: string
  tourId: string,
  teamId: string,
  guides: User[],
  product: Product,
  reservations: ReservationBase[],

  pickups: Pickup[],
  vehicleModel: string,
  vehicleType: string,
  vehicleNumber?: string,
  vehicleRent?: boolean
  driverName?: string,
  driverContact?: string,
  mergeTeam?: string,
  people: number,

  teamNumb: number,
  teamCount: number,
  defined?: boolean,
}

function tourTeamToRows(
  tourTeams: TourTeam[],
  products: { [productId: string]: Product },
  users: { [userId: string]: User },
  area: Area,
): TourTeamRow[] {
  return tourTeams.map((team, idx, teams) => {
    const defined = !!(team.pickup || team.people || team.vehicleModel || team.driverName || team.driverContact || team.mergeTeam);
    const date = team.date;
    const tourId = team.tourId;
    const teamId = team.teamId;
    const guides = team.guideIds.map((gid) => users[gid]);
    const product = products[team.productId];
    const reservations = team.reservations;
    const teamCount = team.teamCount;
    const teamNumb = team.teamIdx + 1;
    const pickups = team.pickup ? [team.pickup] : reducePickups(reservations, product);
    const people = team.people ?? team.reservations.map((r => r.adult + r.kid + r.infant)).reduce((a, b) => a + b, 0);
    const vehicleModel = team.vehicleModel ?? area.assignVehicle(people);
    const [tempVehicleModel, tempVehicleType, tempDriver] = checkVehicleType(vehicleModel);
    const vehicleType = tempVehicleType;
    const vehicleNumber = team.vehicleNumber;
    const driverName = team.driverName ?? tempDriver;
    const driverContact = team.driverContact;
    const mergeTeam = team.mergeTeam;
    return {
      date,
      tourId,
      teamId,
      guides,
      product,
      reservations,
      teamNumb,
      teamCount,
      pickups,
      mergeTeam,
      people,
      vehicleModel,
      vehicleType,
      vehicleNumber,
      driverName,
      driverContact,
      defined,
    }
  })
}

function reducePickups(reservations: ReservationBase[], product: Product): Pickup[] {
  const pickupSet = reservations.reduce((result, reservation) => {
    result.add(reservation.pickupPlace)
    return result;
  }, new Set<string>);

  const sortedPickups = Object.entries(product.chat?.pickup ?? {})
    .filter(([_, p]) => p.use)
    .sort(([_, a], [__, b]) => {
      return ((a.order ?? 99) - (b.order ?? 99));
    })
    .map(([_, pickup]) => pickup.place)
    .filter(pickup => pickupSet.has(pickup))
    .map((pickup) => {
      const time = (product.winter ? product.chat?.pickup?.[pickup]?.winterTime : product.chat?.pickup?.[pickup]?.time) ?? 'Unknown Time';
      return {place: pickup, time};
    })
  return sortedPickups;
}


export default function NewNewBus(
  {date, operation, products, users, onAction, area: _areaString}:
    {
      date: string,
      operation: Operation,
      products: { [productId: string]: Product },
      users: { [userId: string]: User },
      area: string,
      onAction: (tourId: string, teamId: string) => void
    }) {
  const area = AREAS[_areaString];

  const [tourTeamRows, setTourTeamRows] = useState<TourTeamRow[]>([]);
  const sortedTourTemRowsProductTime = useMemo(() => {
    const sortedTourTeamRows = tourTeamRows.sort((aTeam, bTeam) => {
      const aRepresentativePickup = representativePickups(aTeam.product);
      const bRepresentativePickup = representativePickups(bTeam.product);
      const aTime = aRepresentativePickup?.time ?? '99:99';
      const bTime = bRepresentativePickup?.time ?? '99:99';
      if (aTime > bTime) return 1;
      if (aTime < bTime) return -1;
      if (aTeam.product.id > bTeam.product.id) return 1;
      if (aTeam.product.id < bTeam.product.id) return -1;
      return aTeam.teamNumb - bTeam.teamNumb;
    });

    return sortedTourTeamRows;
  }, [tourTeamRows]);
  useEffect(() => {
    if (!operation || !products || !users || !area) return;
    const tourTeams = operationToTeams(operation, date);
    const tourTeamRows = tourTeamToRows(tourTeams, products, users, area)
    const filteredTourTeamRows = tourTeamRows.filter(({product}) => product.area.toLowerCase() === area.id.toLowerCase());
    setTourTeamRows(filteredTourTeamRows);
  }, [operation, products, users, area]);

  const clearTourTeamProperties = (team: TourTeamRow) => {
    const path = `/operation/${date}/tours/${team.tourId}/teams/${team.teamId}`;
    const clearData = {
      dispatch: null
    }
    updateRealtime(path, clearData).catch(console.error);
  }

  const handeChangeTourTeamRowProps = (team: TourTeamRow, property: keyof TourTeamRow, value: any) => {
    const dispatchPath = `/operation/${date}/tours/${team.tourId}/teams/${team.teamId}/dispatch`;
    switch (property) {
      case 'pickups':
        const changePickup = team.product.chat?.pickup?.[value];
        const pickup: Pickup = {
          place: value,
          time: (team.product.winter ? changePickup?.winterTime : changePickup?.time) ?? '99:99'
        };
        const pickups: Pickup[] = [pickup];
        updateRealtime(dispatchPath, {
          pickup,
        }).catch(console.error)
        setTourTeamRows((prev) => {
          return prev.map((prev) => {
            if (prev.teamId !== team.teamId) return prev;
            return {...prev, pickups}
          })
        })
        return;
      case 'driverName':
        const driverName = value;
        updateRealtime(dispatchPath, {
          driverName,
        }).catch(console.error)
        setTourTeamRows((prev) => {
          return prev.map((prev) => {
            if (prev.teamId !== team.teamId) return prev;
            return {...prev, driverName}
          })
        })
        return;
      case 'driverContact':
        const driverContact = value;
        updateRealtime(dispatchPath, {
          driverContact,
        }).catch(console.error)
        setTourTeamRows((prev) => {
          return prev.map((prev) => {
            if (prev.teamId !== team.teamId) return prev;
            return {...prev, driverContact}
          })
        })
        return;
      case 'vehicleModel':
        const [vehicleModel] = checkVehicleType(value);
        updateRealtime(dispatchPath, {
          vehicleModel,
        }).catch(console.error)
        setTourTeamRows((prev) => {
          return prev.map((prev) => {
            if (prev.teamId !== team.teamId) return prev;
            return {...prev, vehicleModel}
          })
        })
        return;
      case 'vehicleNumber':
        const vehicleNumber = value;
        updateRealtime(dispatchPath, {
          vehicleNumber,
        }).catch(console.error)
        setTourTeamRows((prev) => {
          return prev.map((prev) => {
            if (prev.teamId !== team.teamId) return prev;
            return {...prev, vehicleNumber}
          })
        })
        return;
      case 'people':
        const people = value;
        const peopleVehicle = area.assignVehicle(people);
        const [peopleVehicleModel] = checkVehicleType(peopleVehicle);
        updateRealtime(dispatchPath, {
            people,
            vehicleModel: peopleVehicleModel,
          }
        ).catch(console.error)
        setTourTeamRows((prev) => {
          return prev.map((prev) => {
            if (prev.teamId !== team.teamId) return prev;
            return {
              ...prev,
              vehicleModel: peopleVehicleModel,
              people: value
            }
          })
        })
        return;
      case 'mergeTeam':
        const mergeTeam = value;
        updateRealtime(dispatchPath, {mergeTeam}).catch(console.error)
        setTourTeamRows((prev) => {
          return prev.map((prev) => {
            if (prev.teamId !== team.teamId) return prev;
            return {
              ...prev,
              mergeTeam
            }
          })
        })
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <Card>
          <CardContent>
            <Table sx={{width: "100%"}} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">No</TableCell>
                  <TableCell align="center">투어</TableCell>
                  <TableCell align="center">팀</TableCell>
                  <TableCell align="center">예약인원/수</TableCell>
                  <TableCell align="center">가이드</TableCell>
                  <TableCell align="center">첫 픽업</TableCell>
                  <TableCell align="center">배차 크기</TableCell>
                  <TableCell align="center">차량</TableCell>
                  <TableCell align="center">차량번호</TableCell>
                  <TableCell align="center">운전기사</TableCell>
                  <TableCell align="center">운전기사 연락처</TableCell>
                  <TableCell align="center">합승 투어팀</TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  sortedTourTemRowsProductTime.map((t, idx) => {
                    return (
                      <TableRow
                        key={t.teamId}
                        sx={{
                          "&:last-child td, &:last-child th": {border: 0},
                        }}
                      >
                        <TableCell component="th" scope="row" align="center">
                          {idx + 1}
                        </TableCell>
                        <TableCell align={'center'}>
                          {t.product.name}({representativePickups(t.product)?.time ?? '99:99'})
                        </TableCell>
                        <TableCell align={'center'}>
                          {t.teamNumb}
                        </TableCell>
                        <TableCell align={'center'}>
                          <Typography
                            color={t.reservations.reduce((a, b) => a + b.infant + b.kid + b.adult, 0) !== t.people ? 'error' : 'inherit'}>
                            {t.reservations.reduce((a, b) => a + b.infant + b.kid + b.adult, 0)}명/
                            {t.reservations.length}개
                          </Typography>
                        </TableCell>
                        <TableCell align={'center'}>
                          {t.guides.map(g => g.name).join(', ')}
                        </TableCell>

                        <TableCell align={'center'}>
                          <TextField
                            fullWidth
                            select
                            name="pickup"
                            value={t.pickups[0]?.place ?? ''}
                            SelectProps={{native: true,}}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              handeChangeTourTeamRowProps(t, 'pickups', e.target.value)
                            }}
                          >
                            {
                              Object.entries(area.pickupNameMap)
                                .map(([value, label]) => (<option key={value} value={value}>{label}</option>))
                            }
                          </TextField>
                        </TableCell>
                        <TableCell align={'center'}>
                          <TextField
                            fullWidth
                            select
                            name="people"
                            type={'number'}
                            SelectProps={{native: true}}
                            value={t.people}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              handeChangeTourTeamRowProps(t, 'people', Number.parseInt(e.target.value))
                            }}
                          >
                            {(new Array(43).fill(1).map((_, idx) => idx + 1))
                              .map(p => (
                                <option key={p} value={p}>{p}명</option>))
                            }
                          </TextField>
                        </TableCell>
                        <TableCell align={'center'}>
                          <TextField
                            fullWidth
                            select
                            name="vehicleModel"
                            type="text"
                            SelectProps={{native: true}}
                            value={t.vehicleModel}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              handeChangeTourTeamRowProps(t, 'vehicleModel', e.target.value)
                            }}
                          >
                            {area.vehicles.map(p => (<option key={p} value={p}>{p}</option>))}
                          </TextField>
                        </TableCell>
                        <TableCell align={'center'}>
                          <TextField
                            fullWidth
                            name="vehicleNumber"
                            type="text"
                            value={t.vehicleNumber}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              handeChangeTourTeamRowProps(t, 'vehicleNumber', e.target.value)
                            }}
                          />
                        </TableCell>
                        <TableCell align={'center'}>
                          <TextField
                            fullWidth
                            name="driverName"
                            type="text"
                            value={t.driverName}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              handeChangeTourTeamRowProps(t, 'driverName', e.target.value)
                            }}
                          />
                        </TableCell>
                        <TableCell align={'center'}>
                          <TextField
                            fullWidth
                            name="driverContact"
                            type="text"
                            value={t.driverContact}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              handeChangeTourTeamRowProps(t, 'driverContact', e.target.value)
                            }}
                          />
                        </TableCell>
                        <TableCell align={'center'}>
                          <TextField
                            fullWidth
                            select
                            name="mergeTeam"
                            type="text"
                            SelectProps={{native: true}}
                            value={t.mergeTeam ?? ''}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              handeChangeTourTeamRowProps(t, 'mergeTeam', e.target.value)
                            }}
                          >
                            {tourTeamRows.map(ttr => (
                              <option key={ttr.teamId} value={ttr.teamId}>
                                {ttr.product.name}{ttr.teamNumb}({ttr.guides.map(g => g.name).join(', ')})
                              </option>
                            ))}
                            <option key={'none'} value={''}>{''}</option>
                          </TextField>
                        </TableCell>
                        <TableCell align={'center'}>
                          {
                            t.defined
                            && (
                              <IconButton onClick={() => {
                                clearTourTeamProperties(t)
                              }}>
                                <Icon>
                                  restore
                                </Icon>
                              </IconButton>
                            )
                          }

                        </TableCell>
                        <TableCell align={'center'}>
                          <IconButton onClick={() => {
                            onAction(t.tourId, t.teamId)
                          }}>
                            <Icon>
                              launch
                            </Icon>
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )
                  })
                }
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={4}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Card>
              <CardContent>
                <DispatchPhrase tourTeamRows={tourTeamRows} date={date} area={area}/>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card>
              <CardContent>
                <AnnouncementPhrase tourTeamRows={tourTeamRows} date={date} area={area}/>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card>
              <CardContent>
                <DrivingTourPhrase tourTeamRows={tourTeamRows} date={date} area={area}/>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card>
              <CardContent>
                <PrivateTourPhrase tourTeamRows={tourTeamRows} date={date} area={area}/>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

function AnnouncementPhrase({tourTeamRows, area}: {
  tourTeamRows: TourTeamRow[],
  area: Area,
  date: string
}) {
  const filteredTourTeamRows = tourTeamRows.filter(ttr =>
    !PRIVATE_IDS.includes(ttr.product.id)
    && !IGNORE_ANNOUNCE_PRODUCTS.includes(ttr.product.name)
  );
  const phrase = announcement(filteredTourTeamRows, area);
  return (
    <Stack
      gap={1}
    >
      <Stack
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Typography variant={'h6'}>
          공지
        </Typography>
        <IconButton onClick={() => {
          copy(phrase).catch(console.error)
        }}>
          <Icon>copy_all</Icon>
        </IconButton>
      </Stack>
      <TextField
        multiline
        rows={30}
        fullWidth
        value={phrase}
      />
    </Stack>
  )
}


function DispatchPhrase({tourTeamRows, area}: {
  tourTeamRows: TourTeamRow[],
  area: Area,
  date: string
}) {
  const filteredTourTeamRows = tourTeamRows.filter(ttr =>
    !PRIVATE_IDS.includes(ttr.product.id)
    && !IGNORE_ANNOUNCE_PRODUCTS.includes(ttr.product.name)
    && !DRIVING_CARS.includes(ttr.vehicleModel ?? '')
  );

  const phrase = dispatch(filteredTourTeamRows, area, false, true);

  return (
    <Stack
      gap={1}
    >
      <Stack
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Typography variant={'h6'}>
          배차
        </Typography>
        <IconButton onClick={() => {
          copy(phrase).catch(console.error)
        }}>
          <Icon>copy_all</Icon>
        </IconButton>
      </Stack>
      <TextField
        multiline
        rows={30}
        fullWidth
        value={phrase}
      />
    </Stack>
  )

}


function DrivingTourPhrase({tourTeamRows, area}: {
  tourTeamRows: TourTeamRow[],
  area: Area,
  date: string
}) {
  const filteredTourTeamRows = tourTeamRows.filter(ttr =>
    DRIVING_CARS.includes(ttr.vehicleModel ?? '')
    && !PRIVATE_IDS.includes(ttr.product.id)
    && !ttr.product.name.includes('광장시장')
  );

  const phrase = dispatch(filteredTourTeamRows, area, true, true);

  return (
    <Stack
      gap={1}
    >
      <Stack
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Typography variant={'h6'}>
          Driving
        </Typography>
        <IconButton onClick={() => {
          copy(phrase).catch(console.error)
        }}>
          <Icon>copy_all</Icon>
        </IconButton>
      </Stack>
      <TextField
        multiline
        rows={30}
        fullWidth
        value={phrase}
      />
    </Stack>
  )

}

function PrivateTourPhrase({tourTeamRows, area}: {
  tourTeamRows: TourTeamRow[],
  area: Area,
  date: string
}) {
  const filteredTourTeamRows = tourTeamRows.filter(ttr =>
    PRIVATE_IDS.includes(ttr.product.id)
  );

  const phrase = dispatch(filteredTourTeamRows, area, false, true);

  return (
    <Stack
      gap={1}
    >
      <Stack
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Typography variant={'h6'}>
          Private
        </Typography>
        <IconButton onClick={() => {
          copy(phrase).catch(console.error)
        }}>
          <Icon>copy_all</Icon>
        </IconButton>
      </Stack>
      <TextField
        multiline
        rows={30}
        fullWidth
        value={phrase}
      />
    </Stack>
  )

}


function dispatch(tourTeamRows: TourTeamRow[], area: Area, adjustTime?: boolean, addUpGuide?: boolean,) {
  if (tourTeamRows.length <= 0) return '';

  const locale = area.locale;
  const isKorean = locale === 'ko';
  const date = tourTeamRows[0].date
  const productTimeAggregation = aggregateByProductTime(tourTeamRows);
  const formattedDate = dayjs(date).locale(locale).format(area.dateFormat)

  const modifyTime = (pickupTime: string) => {
    if (!adjustTime) return pickupTime;
    const [hours, min] = pickupTime.split(':')
    if (hours === '99') return `${pickupTime}`;
    const time = new Date()
    const parseHour = Number(min) < 15 ? Number(hours) - 1 : Number(hours);
    const parseMin = Number(min) < 15 ? 60 - (15 - Number(min)) : Number(min) - 15;
    time.setHours(parseHour, parseMin);
    return `${time.getHours().toString().padStart(2, '0')}:${time.getMinutes().toString().padStart(2, '0')}`
  }

  const formatRow = (tourTeamRow: TourTeamRow, idx: number) => {
    const product = tourTeamRow.product;
    const pax = (tourTeamRow.people + (addUpGuide ? tourTeamRow.guides.length : 0)) + (isKorean ? '명' : 'pax');
    const startingPickup = tourTeamRow.pickups[0];
    const productPickups = orderedProductPickups(product)
      .map((pickup) => {
        const isWinter = product.winter;
        const time = (isWinter ? pickup.winterTime : pickup.time) ?? '99:99';
        return {
          time: modifyTime(time),
          place: pickup.place,
          name: pickup[locale] ?? pickup.place,
          order: pickup.order ?? 99
        }
      })
    const startingPickupIndex = productPickups.findIndex((p) => p.place === startingPickup?.place)
    const pickups = startingPickupIndex >= 0 ? productPickups.slice(startingPickupIndex, productPickups.length) : [];
    //ko가 언어와 무관하게 Full Name으로 쓰이고 있음
    const pickupPhrases = pickups.map(({name, time}) => `${time} ${name}`).join('\n');
    const representativeGuide = tourTeamRow.guides[0] ?? {name: isKorean ? '미정' : 'Unassigned', tel: ''};
    const guidePhrase = `${isKorean ? '가이드' : 'Guide'}: ${representativeGuide.name}(${representativeGuide.tel})`;
    const vehicleType = checkVehicleType(tourTeamRow.vehicleModel)[1] || tourTeamRow.vehicleModel;
    return `${idx + 1}.${product.chat?.name?.ko ?? product.name} (${pax})` + '\n'
      + pickupPhrases + '\n'
      + vehicleType + '\n'
      + guidePhrase
  }
  const phrase = Object.values(productTimeAggregation).flat(1).map(formatRow).join('\n\n')
  return formattedDate + '\n\n' + phrase

}

function announcement(tourTeamRows: TourTeamRow[], area: Area) {
  if (tourTeamRows.length <= 0) return '';
  const locale = area.locale;
  const isKorean = locale === 'ko';
  const date = tourTeamRows[0].date
  const timeAggregation = aggregateByTime(tourTeamRows);
  const productTimeAggregation = aggregateByProductTime(tourTeamRows);
  const formattedDate = dayjs(date).locale(locale).format(area.dateFormat)
  const textDivider = '\n--------------------------------\n'
  const formatTeam = (tourTeamRow: TourTeamRow) => {
    const product = tourTeamRow.product;
    const teamNumber = tourTeamRow.teamCount > 1 ? tourTeamRow.teamNumb : '';
    const pickup = product.chat?.pickup?.[tourTeamRow.pickups[0].place];
    const time = product?.winter ? pickup?.winterTime : pickup?.time;
    const guideNames = tourTeamRow.guides.slice(0, 1).map(g => g.name).join(' ');
    const [vehicle, vehicleType, driver] = checkVehicleType(tourTeamRow.vehicleModel);
    return `${product.name}${teamNumber} - @${guideNames}` + '\n'
      + `${time} ${pickup?.[area.locale]}` + '\n'
      + `${vehicleType}`
      + `${driver ? `\n${driver}` : ''}`;
  }

  const formatFlag = (flagTime: string, tourTeamRow: TourTeamRow, idx: number) => {
    const index = (flagTime === '07:30' ? 20 : 0) + 1 + idx; //730 만 구분
    const guideName = tourTeamRow.guides?.length > 0
      ? isKorean ? `${tourTeamRow.guides[0]?.name}(${tourTeamRow.guides[0].nameEn})` : tourTeamRow.guides[0].name
      : isKorean ? '미정' : 'Unassigned'
    const pickupExpression = area.pickupNameMap[tourTeamRow.pickups[0].place];
    const teamNumber = tourTeamRow.teamCount > 1 ? tourTeamRow.teamNumb : '';
    return `${index}.${tourTeamRow.product.name}${teamNumber} - ${guideName} - ${pickupExpression}`
  }

  const expression = Object.entries(timeAggregation)
    .sort(([aTime], [bTime]) => {
      const a = aTime.padStart(2, '0');
      const b = bTime.padStart(2, '0');
      return a > b ? 1 : -1;
    })
    .map(([_, tourTeamRows]) => {
      const teamPhrases = tourTeamRows.map(formatTeam).join('\n\n')
      return textDivider + teamPhrases
    }).join('')

  const flagExpression = Object.entries(productTimeAggregation)
    .sort(([aTime], [bTime]) => {
      const a = aTime.padStart(2, '0');
      const b = bTime.padStart(2, '0');
      return a > b ? 1 : -1;
    })
    .map(([flagTime, tourTeamRows]) => {
      const teamPhrases = tourTeamRows.map((tourTeamRow, idx) => formatFlag(flagTime, tourTeamRow, idx)).join('\n')
      const timePhrase = flagTime + ' flag';
      return timePhrase + '\n' + teamPhrases
    }).join('\n\n')

  return formattedDate + expression + area.announcement + flagExpression
}


function aggregateByTime(tourTeamRows: TourTeamRow[]) {
  return tourTeamRows.reduce((result, ttr) => {
    const time = ttr.pickups[0]?.time ?? '99:99';
    if (!(time in result)) {
      result[time] = [];
    }
    result[time] = [...result[time], ttr].sort((a, b) => {
      if (a.product.id > b.product.id) return 1;
      if (a.product.id < b.product.id) return -1;
      if (a.product.id === b.product.id) {
        if (a.teamNumb > b.teamNumb) return 1;
        if (a.teamNumb < b.teamNumb) return -1;
      }
      return 0
    });
    return result;
  }, {} as { [time: string]: typeof tourTeamRows })
}

function aggregateByProductTime(tourTeamRows: TourTeamRow[]) {
  return tourTeamRows.reduce((result, ttr) => {
    const time = representativePickups(ttr.product)?.time ?? '99:99';
    if (!(time in result)) {
      result[time] = [];
    }
    result[time] = [...result[time], ttr].sort((a, b) => {
      if (a.product.id > b.product.id) return 1;
      if (a.product.id < b.product.id) return -1;
      if (a.product.id === b.product.id) {
        if (a.teamNumb > b.teamNumb) return 1;
        if (a.teamNumb < b.teamNumb) return -1;
      }
      return 0
    });
    return result;
  }, {} as { [time: string]: typeof tourTeamRows })
}

function orderedProductPickups(product: Product) {
  return Object.values(product.chat?.pickup ?? {})
    .filter(p => p.use) //이용중 픽업만 처리
    .sort(({order: aOrder}, {order: bOrder}) => {
      return (aOrder ?? 99) > (bOrder ?? 99) ? 1 : -1;
    })
}

function representativePickups(product: Product) {
  const pickups = orderedProductPickups(product);
  return pickups.find((p) => p.place === 'Myungdong') ?? pickups[1] ?? pickups[0];
}